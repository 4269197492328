<template>
  <div class="home">
    <div class="location">
      <div class="location-line">
        <span>{{ location.city }}</span>
        <span>阴 16-20度</span>
      </div>
    </div>
    <div class="search">
      <div class="search-label">
        <div class="search-item" @click="toSearchPage">
          <van-icon name="search" size="20px" />
          <span>查线路、站点</span>
        </div>
      </div>
    </div>

    <div class="buses">
      <van-tabs @click="changeTabs" background="#f4f5f9" line-width="20px">
        <template #nav-right>
          <div class="nav-right-slot">
            <!-- <router-link :to="{ name: 'Station', params: { stationName: nearestStation.name } }"> -->
            <div class="one-key-search" @click="queryByStationName(nearestStation.name)">
              <span class="yjcc">一键查车</span>
            </div>
            <!-- </router-link> -->
          </div>
        </template>
        <van-tab title="附近站点">
          <div v-show="loading"><van-loading size="24px" vertical>加载中...</van-loading></div>
          <div class="stations" v-show="!loading">
            <div class="station first-station">
              <div
                class="station-group"
                @click="queryByStationName(nearestStation.name, nearestStation.lat, nearestStation.lon)"
              >
                <van-icon name="location" />
                <span class="station-name">{{ nearestStation.name }}</span>
                <span class="station-same-num">{{ nearestStation.sameNum }}个站台</span>
                <span class="station-distance">{{ nearestStation.dis }}m</span>
                <div class="arrow"><van-icon name="arrow" size="10px" /></div>
              </div>
              <van-divider />
              <div class="lines">
                <div v-for="line in lineList" :key="line.name">
                  <div class="line-group" @click="queryByLine(line.lineName, line.upperOrDown, line.to)">
                    <div class="float-left">
                      <div>
                        <span class="line-name">{{ line.lineName }}</span>
                      </div>
                      <div>
                        <span class="line-to">方向&#32;</span>
                        <span class="line-to">{{ line.to }}</span>
                      </div>
                    </div>
                    <div class="float-right">
                      <div>
                        <span class="line-neartext">{{ line.neartext }}</span>
                      </div>
                      <div>
                        <span class="line-neardis">{{ line.neardis }}</span>
                      </div>
                    </div>
                  </div>
                  <van-divider />
                </div>
                <div class="buttom" @click="showAllButtom">{{ lineStatus }}</div>
              </div>
            </div>
            <div v-for="station in stations" :key="station.name" class="station">
              <!-- <div :class="{'station':i==0, 'first-station'}"> -->
              <div class="station-group" @click="queryByStationName(station.name, station.lat, station.lon)">
                <van-icon name="location" />
                <span class="station-name">{{ station.name }}</span>
                <span class="station-distance">{{ station.dis }}m</span>
                <div class="arrow"><van-icon name="arrow" size="10px" /></div>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="历史搜索">
          <div class="history-search">
            <div v-for="(history, i) in historySearch" :key="i" class="history-list">
              <!-- 历史查询中的查询站点 -->
              <div
                v-if="history.type == 'station'"
                class="history-item verticle-center"
                @click="queryByStationName(history.name)"
              >
                <van-icon name="filter-o" />
                <span>{{ history.name }}</span>
              </div>
              <!-- 历史查询中的查询线路 -->
              <div
                v-else-if="history.type == 'line'"
                class="history-item verticle-center"
                @click="queryByLine(history.lineName, history.upperOrDown, history.to)"
              >
                <van-icon name="font-o" />
                <span>{{ history.lineName }} &#32;开往&#32;{{ history.to }}</span>
              </div>
              <van-divider />
            </div>
            <div class="clear-history">
              <span class="verticle-center">清除历史数据</span>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Toast } from 'vant'
import { nearStations, linesByStationName } from '@/api/mygolbs'
export default {
  name: 'Home',
  data() {
    return {
      loading: true,
      isGPS: true,
      nearestStation: {},
      stations: [],
      lineList: [],
      isAllLines: 0,
      historySearch: [
        { type: 'line', lineName: 'M3路', to: '马尾青洲公交总站', upperOrDown: 2 },
        { type: 'line', lineName: 'M3路', to: '鼓山公交总站', upperOrDown: 1 },
        { type: 'station', name: '福州市政府站' },
      ],
    }
  },
  computed: {
    ...mapGetters(['location']),
    lineStatus() {
      return this.isAllLines == 0 ? '展开全部' : '收起'
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions(['Position', 'turnToStation', 'turnToLine']),
    //0.获取定位
    //1.获取天气
    //2.根据定位获取附近站点
    //3.附近站点获取经过的公交
    changeTabs() {},
    init() {
      this.Position()
        .then(res => {
          if (res.status == 200) {
            this.queryNearStations()
          }
        })
        .catch(error => {
          Toast(error)
        })
    },
    //打开搜索页
    toSearchPage() {
      this.$router.push({ name: 'Search' })
    },
    queryNearStations() {
      console.log('???', this.location.city, this.location.lat, this.location.lng)
      nearStations(this.location.city, this.location.lat, this.location.lng).then(res => {
        this.loading = false
        const data = res.data
        let lenth = data.length
        if (lenth > 0) {
          this.nearestStation = data[0]
          this.stations = data.slice(1, data.length)
          console.log('?????????')
          console.log('data', data[0].name, data[0].lat, data[0].lon, 0)
          this.queryByStationNameNotAll(data[0].name, data[0].lat, data[0].lon, 0)
        }
      })
    },
    //按站点查询
    queryByStationName(stationName, lat, lng) {
      let params = { stationName: stationName, lat: lat, lng: lng }
      console.log(params)
      this.turnToStation(params)
      this.$router.push({ name: 'BusStation', params: { stationName: stationName, lat: lat, lng: lng } })
    },
    //按站点查询
    showAllButtom() {
      var a = this.isAllLines
      this.isAllLines = 1 ^ a
      this.queryByStationNameNotAll(
        this.nearestStation.name,
        this.nearestStation.lat,
        this.nearestStation.lon,
        this.isAllLines,
      )
    },
    //按站点查询（没有全部）
    queryByStationNameNotAll(stationName, lat, lon, isAllLines) {
      // Toast('查询站点：' + stationName + '，是否全部' + isAllLines)
      console.log(stationName)
      linesByStationName(this.location.city, stationName, lat, lon, isAllLines).then(res => {
        console.log('res:', res)
        const data = res.data
        this.lineList = data
      })
    },
    //按线路查询
    queryByLine(lineName, upperOrDown, to) {
      let params = {
        stationName: this.nearestStation.stationName,
        lineName: lineName,
        upperOrDown: upperOrDown,
        to: to,
      }
      this.turnToLine(params)
      this.$router.push({ name: 'BusLine', params: { lineName: lineName, upperOrDown: upperOrDown, to: to } })
    },
  },
}
</script>
<style lang="scss" scoped>
.home {
  .location {
    height: 40px;
    padding-left: 10px;
    .location-line {
      padding-top: 15px;
      padding-left: 5px;
      span {
        padding: 10px;
      }
    }
  }
  .search {
    height: 80px;
    .search-label {
      margin: 5px 14px;
      height: 60px;
      border: 1px solid #dfe0e2;
      border-radius: 10px;
      background-color: #f4f5fa;
      .search-item {
        padding: 20px;
        span {
          margin-left: 15px;
          font-size: 18px;
          color: #65666a;
        }
      }
    }
  }
  .buses {
    background-color: #f4f5f9;
    padding: 10px;
    // 46px-120px-50px
    height: calc(100vh - 185px);
    min-height: calc(100vh - 185px);
    overflow-y: auto;
    .nav-right-slot {
      width: 30%;
      align-items: center;
      display: table-cell;
      padding: 6px 10px 6px 40px;
      .one-key-search {
        border: 1px solid #f99677;
        width: 58px;
        padding: 4px 7px 4px 7px;
        border-radius: 15px;
        .yjcc {
          color: #f99677;
          font-size: 14px;
          line-height: 20px;
          height: 20px;
        }
      }
    }
    .stations {
      .first-station {
        margin-top: 20px;
        .lines {
          .line-group {
            height: 40px;
            .float-left {
              float: left;
              .line-name {
                color: #373737;
                font-size: 18px;
                line-height: 20px;
                height: 20px;
              }
              .line-to {
                color: #686868;
                font-size: 14px;
                line-height: 20px;
                height: 20px;
              }
            }
            .float-right {
              float: right;
              width: 30%;
              .line-neartext {
                color: #f99677;
                font-size: 18px;
                line-height: 20px;
                height: 20px;
              }
              .line-neardis {
                color: #686868;
                font-size: 14px;
                line-height: 20px;
                height: 20px;
              }
            }
          }
        }
        .buttom {
          text-align: center;
        }
      }
      .station {
        padding: 20px;
        background-color: #fff;
        margin-bottom: 5px;
        // border: 1px solid red;
        border-radius: 8px;
        .station-group {
          height: 20px;
        }
        .station-name {
          font-size: 18px;
          padding-left: 10px;
          padding-right: 5px;
        }
        .station-same-num {
          font-size: 12px;
          color: #8f8f8f;
          padding-left: 2px;
        }
        .station-distance {
          font-size: 12px;
          color: #8f8f8f;
          border-left: 3px solid #8f8f8f;
          padding-left: 2px;
        }
        .arrow {
          float: right;
          padding: 5px;
        }
      }
    }
    .history-search {
      margin-top: 20px;
      // margin-bottom: 5px;
      padding: 20px;
      background-color: #fff;
      border-radius: 8px;
      .history-list {
        height: 40px;
        .history-item {
          span {
            padding-left: 20px;
          }
        }
      }
      .clear-history {
        height: 40px;
        text-align: center;
      }
    }
  }
}
.verticle-center {
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
</style>
